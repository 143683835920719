import React from "react"
import Topbar from '../components/topbar'
import Footer from '../components/footer'
import SEO from '../components/seo'
import {
  Button,
  Col,
  Row,
} from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import IntegratedWith from '../components/integratedWith'

class Pricing extends React.Component {

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Pricing',
        page: '/pricing'
      });
      window.cmAgent.dispatch();
    }

    if (window && window.gtag) {
      // console.log('show pricing');
      window.gtag('config', 'G-KTYYBQVLCK', {'page_path': '/pricing'});
    }
  }

  render() {

    return <div>
      <SEO title="Pricing | Notifuse" />

      <Topbar />

      <div className="pricing">
        <div className="container">
          <h1>Pricing</h1>
          <div className="subtitle">All plans come with a 14-day free trial. No credit card required.</div>

          <Row gutter={24} className="margin-b-xl">
            <Col xs={24} sm={24} md={12} lg={6}>
              <div className="plan">
                <div className="title">Free</div>

                <div className="content">
                  <div className="quota">500</div>
                  <div className="quota-title margin-b-m">users included</div>

                  <div className="then">then upgrade to a bigger plan</div>

                  <div className="price">Free</div>

                  <a href="https://console.notifuse.com/sign-up?plan=free" title="Sign Up"><Button className="cta" type="primary" size="large" block>Start now</Button></a>

                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> <strong>Unlimited notifications</strong></div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Notification APIs & widget</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Online templating engine</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> All channels included</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> 90 days notifications retention</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> 2 collaborators</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> 2 template languages</div>
                  <div className="feature not">Notifications incrementation</div>
                  <div className="feature not">Scheduled notifications <span className="soon">soon</span></div>
                  <div className="feature not">Broadcasting & changelog <span className="soon">soon</span></div>
                  <div className="feature not">Whitelabel</div>
                  <div className="feature not">On-premise</div>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6}>
              <div className="plan">
                <div className="title">Pro</div>

                <div className="content">
                  <div className="quota">1,000</div>
                  <div className="quota-title margin-b-m">users included</div>

                  <div className="then">then +$4.5 /m per 100 additional users</div>

                  <div className="price">$19 <small>/month</small></div>

                  <a href="https://console.notifuse.com/sign-up?plan=pro" title="Sign Up"><Button className="cta" type="primary" size="large" block>Start now</Button></a>

                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> <strong>Unlimited notifications</strong></div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Notification APIs & widget</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Online templating engine</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> All channels included</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> 90 days notifications retention</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Unlimited collaborators</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Unlimited template languages</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Notifications incrementation</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Scheduled notifications <span className="soon">soon</span></div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Broadcasting & changelog <span className="soon">soon</span></div>
                  <div className="feature not">Whitelabel</div>
                  <div className="feature not">On-premise</div>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6}>
              <div className="plan">
                <div className="title">Premium</div>

                <div className="content">
                  <div className="quota">3,000</div>
                  <div className="quota-title margin-b-m">users included</div>

                  <div className="then">then +$4.5 /m per 100 additional users</div>

                  <div className="price">$59 <small>/month</small></div>

                  <a href="https://console.notifuse.com/sign-up?plan=premium" title="Sign Up"><Button className="cta" type="primary" size="large" block>Start now</Button></a>

                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> <strong>Unlimited notifications</strong></div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Notification APIs & widget</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Online templating engine</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> All channels included</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> 90 days notifications retention</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Unlimited collaborators</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Unlimited template languages</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Notifications incrementation</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Scheduled notifications <span className="soon">soon</span></div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Broadcasting & changelog <span className="soon">soon</span></div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Whitelabel</div>
                  <div className="feature not">On-premise</div>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6}>
              <div className="plan">
                <div className="title">Enterprise</div>

                <div className="content">
                  <div className="quota">Unlimited</div>
                  <div className="quota-title margin-b-m">users</div>

                  <div className="then">then +$4.5 /m per 100 additional users</div>

                  <div className="price"><small>from</small> $499 <small>/month</small></div>

                  <a href="mailto:hello@notifuse.com" title="Contact Us"><Button className="cta" type="primary" size="large" block>Contact Us</Button></a>

                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> <strong>Unlimited notifications</strong></div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Notification APIs & widget</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Online templating engine</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> All channels included</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> 90 days notifications retention</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Unlimited collaborators</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Unlimited template languages</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Notifications incrementation</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Scheduled notifications <span className="soon">soon</span></div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Broadcasting & changelog <span className="soon">soon</span></div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> Whitelabel</div>
                  <div className="feature"><CheckOutlined className="padding-r-s" style={{color: '#8BC34A'}} /> On-premise</div>
                </div>
              </div>
            </Col>
          </Row>

          <IntegratedWith />

          <div className="divider"></div>

          <section className="faq">
            <h1>FAQ</h1>

            <Row gutter={48}>
              <Col sm={24} md={12}>
                <div className="question">What is a user?</div>
                <div className="answer">A user is a someone who uses your app, and will receive your notifications.</div>
              </Col>
              <Col sm={24} md={12}>
                <div className="question">What is a collaborator?</div>
                <div className="answer">A collaborator (=admin) is someone who has access to your Notifuse project to configure the notifications.</div>
              </Col>
              <Col sm={24} md={12}>
                <div className="question">Is a credit card required to sign up?</div>
                <div className="answer">No, you can start using Notifuse for free without providing a credit card. You will be notified when your project reaches the limits of the free tier.</div>
              </Col>
              <Col sm={24} md={12}>
                <div className="question">What data is stored & for how long?</div>
                <div className="answer">Notifuse is storing the notifications and its data for 90 days.</div>
              </Col>
              <Col sm={24} md={12}>
                <div className="question">How are the notifications sent?</div>
                <div className="answer">Your app sends notifications to Notifuse via its API, and then Notifuse forwards the messages to the proper channels. You need to connect an SMTP server or connect your email provider (Mailgun, Sendgrid, Postmark...) to send emails.</div>
              </Col>
            </Row>
          </section>
        </div>
      </div>

      <Footer />
    </div>
  }
}

export default Pricing
