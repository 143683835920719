import React, { Component } from "react"

class IntegratedWith extends Component {

  render() {
    return <section className="logos">
      <div className="logos-title">Integrated with the best</div>
        
      <img src="/images/channels/twilio.png" alt="Twilio" />
      <img src="/images/channels/slack.png" alt="Slack" />
      <img src="/images/channels/mailgun.png" alt="Mailgun" />
      <img src="/images/channels/sendgrid.png" alt="Sendgrid" />
      <img src="/images/channels/postmark.png" alt="Postmark" />
      <img src="/images/channels/amazon_ses.png" alt="Amazon SES" />
      <img src="/images/channels/mailjet.png" alt="Mailjet" />
    </section>
  }
}

export default IntegratedWith